<template>
  <div class="successPage">
    <topbar title=" " color="#fff"></topbar>
    <div class="content">
      <div class="title"><img src="@/assets/home/right.png" alt="" /> 申请提现成功</div>
      <div class="text">
        <!-- 3个工作日内自动到账微信零钱。 <br />
        可添加下方二维码迸入本地分销福利群 -->
        {{ qrcode.desc }}
      </div>
      <div class="qrcode">
        <img :src="qrcode.qrcodeUrl" alt="" />
      </div>
      <div class="returnBtn">
        <van-button block type="danger" @click="$router.push('/home')">返回个人中心</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import topbar from '@/component/topbar.vue'
const tenantId = process.env.VUE_APP_TenantId
export default {
  components: { topbar },
  name: '',
  data() {
    return {
      qrcode: {}
    }
  },
  mounted() {
    this.getQrcodeSetting()
  },
  methods: {
    //获取定位地区二维码文案
    getQrcodeSetting() {
      this.$http
        .get(`boom-center-admin-service/app/distribution/qrcode-setting`, {
          headers: {
            'Bm-Area-Id': localStorage['areaId'],
            'Tenant-Id': tenantId,
            'Bm-Member-Token': localStorage['token']
          }
        })
        .then(res => {
          this.qrcode = res || {}
        })
        .catch(err => {
          this.$notify(err.msg)
        })
        .finally(() => {})
    }
  }
}
</script>
<style lang='less' scoped>
.successPage {
  text-align: center;
  .content {
    padding: 20px;
    .title {
      margin-top: 50px;
      height: 18px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
    .text {
      margin-top: 14px;
      height: 40px;
      line-height: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .qrcode {
      margin-top: 20px;
      img {
        width: 92px;
        height: 92px;
      }
    }
    .returnBtn {
      margin-top: 28px;
    }
  }
}
</style>